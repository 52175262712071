import React from 'react';
import Logo from './Logo';
import SocialMediaIcons from './SocialMediaIcons';

const Footer = () => {
  return (
      <div className={'footer'}>
        <div className={'footer-content'}>
          <Logo header={false}/>
          <div className={'footer-text'}>
            <b>Address:&nbsp;</b>Multan Road, Near Mustafa Chowk, Dera Ghazi Khan, Pakistan.<br/>
            <b>UAN: &nbsp;</b>+92 64 111 690 690<br/>
            <b>Email: &nbsp;</b>sales@hondahisun.com | service@hondahisun.com<br/>
            <b>Sales: &nbsp;</b>+92 300 201 3671 | +92 300 201 3582 | +92 300 201 3674<br/>
            <b>Service: &nbsp;</b>+92 300 201 3670 | +92 300 201 3583 | +92 332 621 6121 
          </div>
        </div>

        <SocialMediaIcons size={"2x"}/>

        <div className={'footer-line'}>
          <div className={'row'}>
            <div className={'column'}>
              {new Date().getFullYear()} &copy; Copy rights are reserved by
            </div>
            <div className={'column'}>
              Honda HiSun DG Khan, Pakistan.
            </div>
          </div>
          <div className={'row'}>
            <div className={'column'}>
              Designed & Developed by:
            </div>
            <div className={'column'}>
              <a href={'https://www.twitter.com/ahsanghalib'}
                 target={'_blank'}
                 rel="noopener noreferrer"
                 style={{color: 'white', textDecoration: 'none'}}
              >
                Heptfy Solutions
              </a>
            </div>
          </div>
        </div>
      </div>
  );
};

export default Footer;