import React from 'react';
import Logo from './Logo';
import Navigation from './Navigation';
import { Link } from 'react-router-dom';

const Header = () => {
  return (
      <div className={'container'}>
        <div className={'header'}>
          <Link to={"/"}><Logo header={true}/></Link>
          <Navigation/>
        </div>
      </div>
  );
};

export default Header;