import React from 'react';

interface Props {
  show: boolean;
  clicked?: () => void;
}

const Backdrop = (props: Props) => {
  return props.show ? (
      <div className={'backdrop'} onClick={props.clicked}/>
  ) : null;
};

export default Backdrop;
