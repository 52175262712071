import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons/faBars";
import Backdrop from "./Backdrop";

const Navigation = () => {
  const [showSideDrawer, setSideDrawer] = useState(false);

  const sideDrawerClosedHandler = () => {
    setSideDrawer(false);
  };

  const sideDrawerToggleHandler = () => {
    setSideDrawer(!showSideDrawer);
  };

  return (
    <div>
      <NavBar drawToggleClicked={sideDrawerToggleHandler} />
      <SideDrawer closed={sideDrawerClosedHandler} open={showSideDrawer} />
    </div>
  );
};

export default Navigation;

interface SideDrawerProps {
  open: boolean;
  closed: () => void;
}

const SideDrawer = (props: SideDrawerProps) => {
  let classes = "side-drawer close";
  if (props.open) classes = "side-drawer open";

  return (
    <div>
      <Backdrop show={props.open} clicked={props.closed} />
      <div className={classes} onClick={props.closed}>
        <NavItems />
      </div>
    </div>
  );
};

interface NavBarProps {
  drawToggleClicked: () => void;
}

const NavBar = (props: NavBarProps) => {
  return (
    <div>
      <div className={"mobile-only"}>
        <FontAwesomeIcon icon={faBars} onClick={props.drawToggleClicked} />
      </div>
      <div className={"desktop-only"}>
        <NavItems />
      </div>
    </div>
  );
};

const NavItems = () => {
  return (
    <ul className={"nav-items"}>
      <NavItem link="/" title="Home" />
      <NavItem link="/about" title="About" />
      <NavItem link="/models" title="Models" />
      <li className={"nav-item"}>
        <a href="https://apps.honda.com.pk/OnlineBooking/" target="blank">Book Online</a>
      </li>
      <NavItem link="/contact" title="Contact Us" />
    </ul>
  );
};

interface NavItemProps {
  link: string;
  title: string;
}

const NavItem = (props: NavItemProps) => {
  return (
    <li className={"nav-item"}>
      <NavLink to={props.link} exact activeClassName={"active"}>
        {props.title}
      </NavLink>
    </li>
  );
};
