import React, { useEffect, Suspense } from "react";
import Layout from "./components/Layout";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import { Switch, Route, Redirect } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const Home = React.lazy(() => import("./pages/Home"));
const Models = React.lazy(() => import("./pages/Models"));
const ModelDetails = React.lazy(() => import("./pages/ModelDetails"));
const About = React.lazy(() => import("./pages/About"));
const Contact = React.lazy(() => import("./pages/Contact"));
const Page404 = React.lazy(() => import("./pages/Page404"));

function App() {
  useEffect(() => {
    const heightSet = () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    };
    window.addEventListener("resize", heightSet);
    heightSet();

    return () => {
      window.removeEventListener("resize", heightSet);
    };
  }, []);

  const routes = (
    <Switch>
      <Route path="/" exact component={Home} />
      <Route path="/about" exact component={About} />
      <Route path="/models" exact component={Models} />
      <Route path="/models/:recId" exact component={ModelDetails} />
      <Route path="/contact" exact component={Contact} />
      <Route exact path="/404" component={Page404} />
      <Route path="*" render={() => <Redirect to="/404" />} />
    </Switch>
  );

  return (
    <Suspense
      fallback={
        <div
          style={{
            height: "100vh",
            width: "100%",
            margin: "0 auto",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div>
            <BeatLoader color={"#e60000"} loading={true} size={20} />
          </div>
        </div>
      }
    >
      <SimpleBar style={{ height: "100vh" }}>
        <Layout>{routes}</Layout>
      </SimpleBar>
    </Suspense>
  );
}

export default App;
