import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faFacebookSquare, faInstagramSquare, faTwitterSquare,
  faYoutubeSquare,
} from '@fortawesome/free-brands-svg-icons';
import {SizeProp} from '@fortawesome/fontawesome-svg-core';

interface Props {
  size: SizeProp
}

const SocialMediaIcons = (props: Props) => {
  return (
      <div className={'social-media-icons'}>
        <a href="https://www.facebook.com/" target="blank">
          <FontAwesomeIcon
              icon={faFacebookSquare}
              size={props.size}
              style={{
                color: '#fff',
                marginRight: '15px',
              }}
          />
        </a>
        <a
            href="https://www.youtube.com/"
            target="blank"
        >
          <FontAwesomeIcon
              icon={faYoutubeSquare}
              size={props.size}
              style={{
                color: '#fff',
                marginRight: '15px',
              }}
          />
        </a>
        <a
            href="https://www.twitter.com/"
            target="blank"
        >
          <FontAwesomeIcon
              icon={faTwitterSquare}
              size={props.size}
              style={{
                color: '#fff',
                marginRight: '15px',
              }}
          />
        </a>
        <a
            href="https://www.instagram.com/"
            target="blank"
        >
          <FontAwesomeIcon
              icon={faInstagramSquare}
              size={props.size}
              style={{
                color: '#fff',
              }}
          />
        </a>
      </div>
  );
};

export default SocialMediaIcons;